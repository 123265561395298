// HomePage.js
import React from 'react';
import { Container } from 'react-bootstrap';
// import './HomePage.css'; // Import your custom styles
import AnimatedTutorials from './AnimatedTutorials';
import RealGameHighlights from './RealGameHighlights';
import SimpleTechniqueGuides from './SimpleTechniqueGuides';
import HealthyHabits from './HealthyHabits';

const SoccerTips = () => {
  return (
    <Container>
      <AnimatedTutorials />
      <RealGameHighlights />
      <SimpleTechniqueGuides />
      <HealthyHabits />
    </Container>
  );
};

export default SoccerTips;
