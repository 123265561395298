import React from 'react';
import { Container, Card, Form, Button, Row } from 'react-bootstrap';

const ContactAndSocialMedia = () => {
  return (
    <Container className="">

      <Row className="m-4 p-4 container-background1">
      <h2 className='header-title'>Contact Us</h2>
      <p className='subtitle'>Reach out to us and stay connected through social media for updates and community engagement!</p>

      <Card className="">
        <Card.Body>
          {/* <Card.Title>Contact Form</Card.Title> */}
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Your Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter your email" />
            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Your message" />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
      </Row>

      <Row className="m-4 p-4 container-background2">
        <h2 className='header-title'>Social Media Links</h2>
        <p className='subtitle'>Stay updated and engaged through our social media channels:</p>

      <Card className="mt-4">
        <Card.Body>
          
          {/* Add social media icons/links as needed */}
          <div>
            <a href="https://twitter.com/example" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter-square fa-3x mr-3"></i>
            </a>
            <a href="https://facebook.com/example" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-square fa-3x mr-3"></i>
            </a>
            <a href="https://instagram.com/example" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram-square fa-3x"></i>
            </a>
          </div>
        </Card.Body>
      </Card>
      </Row>
      
      {/* Add more content and links as needed */}
    </Container>
  );
};

export default ContactAndSocialMedia;
