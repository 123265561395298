import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';

const SimpleTechniqueGuides = () => {
    return (
        <div className="m-4 p-4 container-background3">
            <h2 className='header-title'>Simple Technique Guides</h2>
            <p className='subtitle'>Learn basic soccer techniques with these clear and concise guides!</p>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Dribbling Techniques</Card.Title>
                            <Card.Text>
                                Master the art of dribbling with our step-by-step guide.
                            </Card.Text>
                            <Button variant="primary">Read Guide</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className="">
                        <Card.Body>
                            <Card.Title>Passing Fundamentals</Card.Title>
                            <Card.Text>
                                Improve your passing skills with our easy-to-follow instructions.
                            </Card.Text>
                            <Button variant="primary">Read Guide</Button>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Add more technique guides as needed */}

                {/* Add interactive quizzes component here */}
            </Row>
        </div>
    );
};

export default SimpleTechniqueGuides;
