// PrintableActivities.js
import React from 'react';
import { Button, Card, Row, Col } from 'react-bootstrap';

const PrintableActivities = () => {
  return (
    <div className="m-4 p-4  container-background1">
      <h2 className='header-title'>Printable Activities</h2>
      <p className='subtitle'>Download and print these soccer-themed coloring pages and activities!</p>

      
      <Button variant="success" href="link-to-coloring-pages.pdf" download style={{marginBottom: "20px"}}>
        Download Coloring Pages
      </Button>

      <Row className="">
        <Col>
          <Card>
            {/* Add soccer-themed puzzles component here */}
            <Card.Body>
              <Card.Title>Soccer Puzzles</Card.Title>
              <Card.Text>Enjoy these soccer-themed puzzles!</Card.Text>
              <Button variant="primary">Download Puzzles</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            {/* Add activity sheets component here */}
            <Card.Body>
              <Card.Title>Activity Sheets</Card.Title>
              <Card.Text>Have fun with these soccer activity sheets!</Card.Text>
              <Button variant="primary">Download Activity Sheets</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PrintableActivities;
