import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Row, Col, Image } from "react-bootstrap";
import { motion } from "framer-motion";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import content from '../../website/content.json';
import defaultProfileImage from './default-profile-image.png';
import { ReactComponent as AttachIcon } from './attach-icon.svg'; // Adjust the path as needed


const UserInfo = (props) => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageBase64, setSelectedImageBase64] = useState(null);

  const { user } = props;
  const authUser = useSelector((state) => state.auth.authUser)
  const program = content.home.program.plans.find(i => i.id === props.programId);

  const apiKey = process.env.REACT_APP_KIDDI_SOCCER_SERVICE_API_KEY;
  const apiBaseUrl = process.env.REACT_APP_KIDDI_SOCCER_SERVICE_BASE_URL;

  const getImageByBase64 = (base64String) => {
 
    if(!base64String) {
      return ;
    }

    const binaryData = atob(base64String.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      view[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: 'image/png' });
    const imageUrl = URL.createObjectURL(blob);

    // setSelectedImage(imageUrl);
    return imageUrl;
  }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      "registeredBy": {
        "uid": authUser?.uid,
        "name": authUser?.displayname,
        "email": authUser?.email
      },

      "playground": {
        "id": "TdZNTLjXdcfEEwA3hIVA",
        "address": {
          "zipCode": "66215",
          "city": "Lenexa",
          "addressLine1": "8875 Rosehill Rd",
          "geopoint": {
            "latitude": "38.968000",
            "longitude": "-94.732290"
          },
          "state": "KS"
        },
        "name": "All American Indoor Sports Inc",
        "description": "All American Indoor Sports Inc"
      },
      "team": {
        "name": "Tiger",
        "description": "",
        "id": "1"
      },
      "position": "goalkeeper",
      "rank": 0,

      "startDate": new Date(),
      "endDate": "",
      "timing": [
        {
          "startTime": "06:00 PM",
          "endTime": "07:00 PM"
        }
      ],

      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      birthDate: user?.birthDate || "",
      gender: user?.gender || "Male",
      email: user?.email || "",
      phone: user?.phone || "",
      "profileImage": user?.profileImage,
      "photos": [],
      address: {
        addressLine1: user?.address?.addressLine1 || "",
        addressLine2: user?.address?.addressLine2 || "",
        city: user?.address?.city || "",
        state: user?.address?.state || "",
        zipCode: user?.address?.zipCode || "",
        geopoint: {
          "_longitude": 0,
          "_latitude": 0
        },
      },
      "registration": "completed",
      "program": {
        id: props.programId,
        ...program
      },
      "payment": {
        ...program?.cost?.find(i => i.active),
        "history": [],
        "status": "not-initiated"
      }
    }
  });


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // setSelectedImage(URL.createObjectURL(file));

    // Create a FileReader instance
    const reader = new FileReader();

    // Define a callback function to handle the FileReader's load event
    reader.onload = (event) => {
      const base64String = event.target.result;
      setSelectedImageBase64(base64String);
      getImageByBase64(base64String);
    };

    // Read the file as a data URL (base64)
    reader.readAsDataURL(file);
  };

  const onSubmit = async (data) => {
    const req = {
      ...data,
      profileImage: selectedImageBase64
    }
    try {
      const response = await axios.post(`${apiBaseUrl}/api/kiddisoccer/user`, req, {
        headers: {
          'Authorization': `Bearer ${authUser.accessToken}`
        },
      });

      // setUsers(response.data)
      navigate("/users")
    } catch (e) {
      console.log(e)
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <motion.div
        className="col-md-12"
        initial={{ x: "-100vw" }}
        animate={{ x: 0 }}
        transition={{ stiffness: 150 }}
      >
        {
          program && <div>
            <p className="subtitle" style={{ fontWeight: 'bold' }}>Registration: {program.name}</p>
            <p>{program.subtitle}</p>
            <hr />
          </div>
        }

        <Row>
          
          <Col sm={8}>
            <p className="subtitle">General Information</p>
            <Row>
              <Form.Group controlId="firstName" className="mb-3 col">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  readOnly={!props.isNewUser}
                  type="text"
                  name="firstName"
                  placeholder="Enter your first name"
                  autoComplete="off"
                  {...register("firstName", {
                    required: "First name is required.",
                    pattern: {
                      value: /^[a-zA-Z]+$/,
                      message: "First name should contain only characters."
                    }
                  })}
                  className={`${errors.firstName ? "input-error" : ""}`}
                />
                {errors.firstName && (
                  <p className="text-danger">{errors.firstName.message}</p>
                )}
              </Form.Group>

              <Form.Group controlId="lastName" className="mb-3 col">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  readOnly={!props.isNewUser}
                  type="text"
                  name="lastName"
                  placeholder="Enter your last name"
                  autoComplete="off"
                  {...register("lastName", {
                    required: "Last name is required.",
                    pattern: {
                      value: /^[a-zA-Z]+$/,
                      message: "Last name should contain only characters."
                    }
                  })}
                  className={`${errors.lastName ? "input-error" : ""}`}
                />
                {errors.lastName && (
                  <p className="text-danger">{errors.lastName.message}</p>
                )}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="birthDate" className="mb-3 col">
                <Form.Label>Birth Date</Form.Label>
                <Form.Control
                  readOnly={!props.isNewUser}
                  type="date"
                  name="birthDate"
                  placeholder="Enter your Birth Date"
                  autoComplete="off"
                  {...register("birthDate", {
                    required: "Birth Date is required.",
                    pattern: {
                      value: /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/,
                      message: "Birth Date is not valid."
                    }
                  })}
                  className={`${errors.birthDate ? "input-error" : ""}`}
                />
                {errors.birthDate && (
                  <p className="text-danger">{errors.birthDate.message}</p>
                )}
              </Form.Group>

              <Form.Group controlId="gender" className="mb-3 col">
                <Form.Label>Gender</Form.Label>
                <Form.Select aria-label="Default select"  {...register("gender")} disabled={!props.isNewUser}>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Select>

              </Form.Group>
            </Row>
            <Row>

              <Form.Group controlId="email" className="mb-3 col">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  readOnly={!props.isNewUser}
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  autoComplete="off"
                  {...register("email", {
                    required: "Email is required.",
                    pattern: {
                      value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      message: "Email is not valid."
                    }
                  })}
                  className={`${errors.user_email ? "input-error" : ""}`}
                />
                {errors.user_email && (
                  <p className="text-danger">{errors.user_email.message}</p>
                )}
              </Form.Group>

              <Form.Group controlId="phone" className="mb-3 col">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  readOnly={!props.isNewUser}
                  type="phone"
                  name="phone"
                  placeholder="Enter your phone number"
                  autoComplete="off"
                  {...register("phone", {
                    required: "Phone is required.",
                    pattern: {
                      value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                      message: "Phone number is not valid."
                    }
                  })}
                  className={`${errors.phone ? "input-error" : ""}`}
                />
                {errors.phone && (
                  <p className="text-danger">{errors.phone.message}</p>
                )}
              </Form.Group>

              <p className="subtitle">Address</p>
              <Row>
                <Form.Group controlId="addressLine1" className="mb-3 col-8">
                  <Form.Label>Address Line1</Form.Label>
                  <Form.Control
                    readOnly={!props.isNewUser}
                    type="text"
                    name="addressLine1"
                    placeholder="Enter your address line1"
                    autoComplete="off"
                    {...register("address.addressLine1", {
                      required: "Address is required.",
                      pattern: {
                        value: /^[a-zA-Z0-9\s.,#-]+$/,
                        message: "Address is not valid."
                      }
                    })}
                    className={`${errors.address?.addressLine1 ? "input-error" : ""}`}
                  />
                  {errors.address?.addressLine1 && (
                    <p className="text-danger">{errors.address?.addressLine1.message}</p>
                  )}
                </Form.Group>

                <Form.Group controlId="addressLine2" className="mb-3 col-4">
                  <Form.Label>Address Line2</Form.Label>
                  <Form.Control
                    readOnly={!props.isNewUser}
                    type="text"
                    name="addressLine2"
                    placeholder="Enter your apt / unit number"
                    autoComplete="off"
                    {...register("address.addressLine2")}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="city" className="mb-3 col">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    readOnly={!props.isNewUser}
                    type="text"
                    name="city"
                    placeholder="Enter your city"
                    autoComplete="off"
                    {...register("address.city", {
                      required: "City is required.",
                      pattern: {
                        value: /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
                        message: "City is not valid."
                      }
                    })}
                    className={`${errors?.address?.city ? "input-error" : ""}`}
                  />
                  {errors?.address?.city && (
                    <p className="text-danger">{errors?.address?.city?.message}</p>
                  )}
                </Form.Group>

                <Form.Group controlId="state" className="mb-3 col">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    readOnly={!props.isNewUser}
                    type="text"
                    name="state"
                    placeholder="Enter your state"
                    autoComplete="off"
                    {...register("address.state", {
                      required: "State is required.",
                      pattern: {
                        value: /^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$/,
                        message: "State is not valid."
                      }
                    })}
                    className={`${errors?.address?.state ? "input-error" : ""}`}
                  />
                  {errors?.address?.state && (
                    <p className="text-danger">{errors?.address?.state?.message}</p>
                  )}
                </Form.Group>


                <Form.Group controlId="zipCode" className="mb-3 col">
                  <Form.Label>ZipCode</Form.Label>
                  <Form.Control
                    readOnly={!props.isNewUser}
                    type="text"
                    name="zipCode"
                    placeholder="Enter your zipCode"
                    autoComplete="off"
                    {...register("address.zipCode", {
                      required: "ZipCode is required.",
                      pattern: {
                        value: /^\d{5}(?:[-\s]\d{4})?$/,
                        message: "ZipCode is not valid."
                      }
                    })}
                    className={`${errors?.address?.zipCode ? "input-error" : ""}`}
                  />
                  {errors?.address?.zipCode && (
                    <p className="text-danger">{errors?.address?.zipCode.message}</p>
                  )}
                </Form.Group>

              </Row>

            </Row>
          </Col>

          <Col sm={4}>

            <Row style={{ textAlign: "center" }}>
              {/* Render selected image */}
              <div className="profile-image-container m-auto p-4">
                <Image 
                  src={user?.profileImage ? getImageByBase64(user?.profileImage): defaultProfileImage} 
                  alt="Profile"
                  roundedCircle
                  className="profile-image"
                />
              </div>
              
              {/* Image profile field */}
              <Form.Group controlId="profileImage">
                <Form.Control
                  readOnly={!props.isNewUser}
                  type="file"
                  name="profileImage"
                  accept="image/*"
                  className="file-input"
                  {...register('profileImage', { required: 'Profile image is required' })}
                  onChange={handleFileChange}
                />
                <label htmlFor="profileImage" className="attach-icon-label">
                  <AttachIcon className="attach-icon" />
                  <Form.Label>Change Profile Image</Form.Label>
                </label>
                {errors.profileImage && <p className="text-danger">{errors.profileImage.message}</p>}
              </Form.Group>


            </Row>

          </Col>

        </Row>

        <hr />
        <Row className="m-4">
          <Col sm={8}>
          </Col>
          {props.isNewUser && <Col sm={4}>
            <Button className="m-1" variant="primary" type="submit">
              Submit
            </Button>
            <NavLink to="/">
              <Button className="m-1" variant="secondary" >
                Cancel
              </Button>
            </NavLink>
          </Col>}
        </Row>


      </motion.div>
    </Form>
  );
};

export default UserInfo;
