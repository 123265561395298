// HomePage.js
import React from 'react';
import { Container } from 'react-bootstrap';
// import './HomePage.css'; // Import your custom styles
import PrintableActivities from './PrintableActivities';
import FunFacts from './FunFacts';

const FunActivities = () => {
  return (
    <Container>
        <PrintableActivities />
        <FunFacts />
    </Container>
  );
};

export default FunActivities;
