import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleAuthProvider, getAuth, signInWithPopup, signOut } from "firebase/auth";
import { signinUser, logout } from '../../redux/authSlice';
import { Button, Col, Navbar, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const GmailAuthComponent = () => {

    const dispatch = useDispatch()
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    const auth = getAuth();

    const authUser = useSelector((state) => state.auth.authUser)
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

    useEffect(() => {
        const refreshToken = async () => {
            try {
                const user = auth.currentUser;

                if (user) {
                    // const { refreshToken } = user;

                    const { displayName, email, uid, photoURL } = user;
                    // Implement your logic to refresh the Gmail access token using the refresh token.
                    // You may need to make a request to the Gmail API to refresh the token.

                    // Example using Firebase Auth API (Note: This is not Gmail-specific)
                    const refreshedIdToken = await user.getIdToken(true);
                    dispatch(signinUser({ displayName, email, uid, photoURL, accessToken: refreshedIdToken }));
                }
            } catch (error) {
                console.error('Error refreshing token:', error);
            }
        };

        // Refresh the token every 30 minutes (adjust as needed)
        const refreshTokenInterval = setInterval(refreshToken, 5 * 60 * 1000);

        return () => clearInterval(refreshTokenInterval);
    }, [auth]);


    function signInWithGoogle() {
        signInWithPopup(auth, provider)
            .then((result) => {
                // const credential = GoogleAuthProvider.credentialFromResult(result); // This gives you a Access Token. You can use it to access the Google API.
                // const token = credential.accessToken; // Access token of user
                
                const user = result.user; // The signed-in user info.

                if (user) {
                    const { displayName, email, uid, photoURL } = user;
                    dispatch(signinUser({ displayName, email, uid, photoURL }));

                    user.getIdToken().then((tkn) => {
                        dispatch(signinUser({ displayName, email, uid, photoURL, accessToken: tkn }));
                    })
                }
            })
            .catch((e) => {
                console.log(e)
                // Handle Errors here.
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // // The email of the user's account used.
                // const email = error?.customData?.email;
                // // The AuthCredential type that was used.
                // const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }

    function signOutWithGoogle() {
        signOut(auth).then(() => {
            dispatch(logout());
            window.location.replace("/");
        }).catch((e) => {
            console.log(e)
            // alert(error);
        });
    }

    // Render your Gmail authentication component UI
    return (
        <>
            {!isAuthenticated && <Button variant="link" onClick={signInWithGoogle}>Signin with Google</Button>}
            {
                isAuthenticated && <Row>
                <Col>
                    <Navbar.Text className="" style={{textAlign: "center"}}>Signed in as: {authUser?.email}</Navbar.Text>
                </Col>
                <Col>
                    <Navbar style={{justifyContent: "flex-end"}}><NavLink className="p-0" to="/users" style={{textAlign: "center"}}>My Users</NavLink></Navbar>
                </Col>
                <Col>
                    <Button variant="link" onClick={signOutWithGoogle}>Logout</Button>
                </Col>
              </Row>
            }
        </>
        
    );
};

export default GmailAuthComponent;
