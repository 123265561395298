import React from 'react'
import axios from 'axios'
import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { motion } from "framer-motion";
import UserInfo from '../components/registration/UserInfo';
import { Container, Image } from 'react-bootstrap';
import UserProfile from '../components/registration/UserProfile';
import defaultProfileImage from '../components/registration/default-profile-image.png';
import { useSelector } from 'react-redux';

export default function Users(props) {
  const authUser = useSelector((state) => state.auth.authUser)
  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

  const [users, setUsers] = useState([])
  
  const apiKey = process.env.REACT_APP_KIDDI_SOCCER_SERVICE_API_KEY;
  const apiBaseUrl = process.env.REACT_APP_KIDDI_SOCCER_SERVICE_BASE_URL;

  const getRegisteredUsers = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/kiddisoccer/user`, {
        headers: {'Authorization': `Bearer ${authUser.accessToken}`}
      });

      setUsers(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  const getImageByBase64 = (base64String) => {
 
    if(!base64String) {
      return ;
    }

    const binaryData = atob(base64String.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      view[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: 'image/png' });
    const imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  }

  useEffect(() => {
    getRegisteredUsers();
  }, []);

  return (
    <Container>
      <motion.div
        className="col-md-12"
        initial={{ x: "-100vw" }}
        animate={{ x: 0 }}
        transition={{ stiffness: 150 }}
      >
      {
        users && users.length > 0 && <div className='m-4 p-4 container-background3'>
          <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
            <Row>
              <Col sm={3} className='m-auto'>
                <p className='subtitle'>Registered Users ({users.length})</p>
                <ListGroup>
                  {users.map((user, index) => (
                    <ListGroup.Item key={index} action href={`#link${index}`} >
                      <Row>
                        <Col sm={3}>
                          <div className='list-profile-image-container'>
                            <Image
                              src={user.profileImage
                                ? getImageByBase64(user.profileImage)
                                : defaultProfileImage}
                              alt="Profile"
                              roundedCircle
                              className="profile-image"
                            />
                          </div>
                        </Col>
                        <Col className='m-auto'>
                          <h6 style={{ fontWeight: 'bold' }}>{`${user.firstName} ${user.lastName}`}</h6>
                          {/* <hr /> */}
                          <Row>
                            <Col>{`${user?.program?.name}`}</Col>
                            {/* <Col>{`Position: ${user.position}`}</Col> */}
                            {/* <Col>{`Rank: ${user.rank}`}</Col> */}
                          </Row>
                        </Col>
                      </Row>

                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
              <Col sm={9}>
                <Tab.Content style={{ alignContent: "left", textAlign: "left" }}>
                  {users.map((user, index) => (
                    <Tab.Pane eventKey={`#link${index}`}>
                        <UserInfo user={user} isNewUser={false} />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>

      }

      </motion.div>

    </Container>
  )
}