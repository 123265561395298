import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';

const HealthyHabits = () => {
  return (
    <div className="m-4 p-4 container-background4">
      <h2 className='header-title'>Healthy Habits</h2>
      <p className='subtitle'>Stay active and healthy with these tips and fun challenges!</p>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Stay Active</Card.Title>
              <Card.Text>
                Discover fun exercises to stay active and improve your soccer skills.
              </Card.Text>
              <Button variant="success">Get Moving</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="">
            <Card.Body>
              <Card.Title>Eat Well</Card.Title>
              <Card.Text>
                Learn about nutritious foods that fuel young soccer players.
              </Card.Text>
              <Button variant="success">Explore Foods</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="">
            <Card.Body>
              <Card.Title>Get Proper Rest</Card.Title>
              <Card.Text>
                Understand the importance of getting enough rest for peak performance.
              </Card.Text>
              <Button variant="success">Rest Tips</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Add fun challenges component here */}
    </div>
  );
};

export default HealthyHabits;
