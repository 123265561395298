import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const FunFacts = () => {
  return (
    <div className="m-4 p-4 container-background2">
      <h2 className='header-title'>Fun Facts</h2>
      <p className='subtitle'>Explore interesting soccer trivia in a playful and captivating way!</p>
        <Row>
            <Col>
      <Card>
        <Card.Body>
          <Card.Title>Trivia Corner</Card.Title>
          <Card.Text>
            Did you know? The highest-scoring soccer game in history was
            149-0! It took place in 2002 between two teams in Madagascar.
          </Card.Text>
        </Card.Body>
      </Card>
      </Col>
      <Col>
      <Card className="">
        <Card.Body>
          <Card.Title>"Did You Know?" Facts</Card.Title>
          <Card.Text>
            Did you know? Pele, one of the greatest soccer players of all time,
            scored over 1,000 career goals!
          </Card.Text>
        </Card.Body>
      </Card>
      </Col>
      </Row>

      {/* Add more fun facts as needed */}
    </div>
  );
};

export default FunFacts;
