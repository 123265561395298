import React from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import UserInfo from '../components/registration/UserInfo';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function RegisterUser(props) {
  const { programId } = useParams();

  // const authUser = useSelector((state) => state.auth.authUser)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
 
  useEffect(() => {
  }, []);

  return (
    <Container className='m-4 p-4 container-background1'>
      <UserInfo isNewUser={true} programId={programId}/>
    </Container>
  )
}