// WelcomeBanner.js
import React from 'react';
import { Container, Row, Col, Carousel, Card, Image } from 'react-bootstrap';
import ProgramPlans from './ProgramPlans';
import content from '../content.json';
import './HomePage.css'

const WelcomeBanner = () => {
    return (
        <Container className="m-4 p-4">


            <div className="welcome-banner container-background1">
                <Row className='m-auto'>
                    <Col sm={2} className='m-auto'><Image src="logo.svg" alt="Kiddi-Soccer" style={{height: "200px", opacity: 1}}/></Col>
                    <Col sm={10} className='m-auto'>
                        <Row>
                            <h1 className='header-title'>{content.home.banner.title}</h1>
                            
                        </Row>
                        <Row className='m-auto'>
                            <p className='subtitle'>{content.home.banner.subtitle}</p>
                        </Row>
                    </Col>
                </Row>
            </div>

            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={"/adria-crehuet-cano-LIhB1_mAGhY-unsplash.jpg"}
                        alt="First slide"
                    />
                    Photo by <a href="https://unsplash.com/@acrehuet98?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Adrià Crehuet Cano</a> on <a href="https://unsplash.com/photos/children-playing-soccer-LIhB1_mAGhY?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={"/debra-brewster-AvGIpReokFM-unsplash.jpg"}
                        alt="Second slide"
                    />
                    Photo by <a href="https://unsplash.com/@dbrewster66?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Debra Brewster</a> on <a href="https://unsplash.com/photos/boy-in-red-and-blue-soccer-jersey-kicking-soccer-ball-on-green-grass-field-during-daytime-AvGIpReokFM?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={"/hal-gatewood-AzDHa9F9uBY-unsplash.jpg"}
                        alt="Third slide"
                    />
                    Photo by <a href="https://unsplash.com/@halacious?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Hal Gatewood</a> on <a href="https://unsplash.com/photos/person-playing-soccer-on-field-AzDHa9F9uBY?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                </Carousel.Item>
            </Carousel>

            <Row className='mr-4 ml-4 mb-4 p-4 location container-background2'>
                <Col>
                    <h2 className='header-title'>{content.home.location.header}</h2>
                    <hr />
                    <p className='subtitle'>{content.home.location.title}</p>
                    <Card.Title>{content.home.location.subtitle}</Card.Title>
                </Col>
                <Col>
                    <h2 className='header-title'>{content.home.practice.header}</h2>
                    <hr />
                    <p className='subtitle'>{content.home.practice.title}</p>
                    <Card.Title>{content.home.practice.subtitle}</Card.Title>
                </Col>
            </Row>
            <Row className='program container-background3'>
                <div className='ml-4 mr-4 mb-4 p-4'>
                    <h2 className='header-title'>{content.home.program.header}</h2>
                    <hr />
                    <p className='subtitle'>{content.home.program.subtitle}</p>
                </div>
                    
                <ProgramPlans plans={content.home.program.plans} />
            </Row>
            <a href="https://www.freepik.com/free-vector/many-boys-playing-football-illustration_1169130.htm#query=kids%20soccer&position=7&from_view=keyword&track=ais&uuid=0659a7ff-3cf3-4c5d-a767-dd633e11550d">Image by brgfx</a> on Freepik
        </Container>

    );
};

export default WelcomeBanner;
