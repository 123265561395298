import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Users from './pages/Users';
import CollapsibleNavbar from './components/Header';

// import FirstStep from "./components/registration/FirstStep";
// import SecondStep from "./components/registration/SecondStep";
import HomePage from './website/home/HomePage';
import ContactAndSocialMedia from './website/contact/ContactAndSocialMedia';
import SoccerTips from './website/techniques/SoccerTips';
import FunActivities from './website/activities/FunActivities';
import ParentsCorner from './website/parents/ParentsCorner';
import "./App.css";
import RegisterUser from './pages/RegisterUser';


function App() {
  
  return (
    <div className="App app-background">
      <BrowserRouter>
      <CollapsibleNavbar />
      <div className="container">
        <Routes>
          <Route
            path="/"
            element={<HomePage />}
          />
          <Route
            path="/register/:programId"
            element={<RegisterUser isNewUser={true} user={null} />}
          />
          <Route
            path="/users"
            element={<Users isNewUser={false} />}
          />
          <Route
            path="/activities"
            element={<FunActivities/>}
          />
          <Route
            path="/soccer-tips"
            element={<SoccerTips/>}
          />
          <Route
            path="/parents-corner"
            element={<ParentsCorner/>}
          />
          <Route
            path="/contact"
            element={<ContactAndSocialMedia/>}
          />
          {/* <Route
            path="/register/firstStep"
            element={<FirstStep user={user} updateUser={updateUser} />}
          />
          <Route
            path="/register/secondStep"
            element={<SecondStep user={user} updateUser={updateUser} />}
          /> */}

          {/* <Route render={() => <Redirect to="/" />} /> */}
        </Routes>
      </div>
    </BrowserRouter>
    </div>
  );
}

export default App;