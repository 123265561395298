import React from 'react';
import { Card, Container, Row, Col, ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
  
const ProgramPlans = (props) => {
    
    return (
        <Container className="m-0 p-0">
            <Row>
                {props.plans.map((plan, index) => (
                    <Col key={index} md={4} className="mb-3">
                        <Card className=''>
                            <Card.Img variant="top" src={`/${plan.image}`} ></Card.Img>
                            
                            <div dangerouslySetInnerHTML={{ __html: plan.imageAttribution }} />
                            <Card.Body>
                                <Card.Title>{plan.name}</Card.Title>
                                <Card.Subtitle className="mb-2">{plan.subtitle}</Card.Subtitle>

                                <Card.Text style={{fontSize: 16}}>
                                    {plan.shortDescription}
                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                {
                                    plan.cost && plan.cost.map((c, index1) => {
                                        return c.active && <ListGroupItem key={index1}>
                                            <Card.Subtitle className="mb-2">{c.title}</Card.Subtitle>
                                            <Card.Text>
                                                {c.description}
                                            </Card.Text>
                                            {/* {`${c.title}: $${c.amount}`} */}
                                        </ListGroupItem>
                                    })
                                }
                            </ListGroup>
                            <Card.Body>
                                {/* <Card.Link href="#">Register Now</Card.Link> */}
                                <Link to={`/register/${plan.id}`}>
                                    <Button variant="primary">Register Now</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default ProgramPlans;
