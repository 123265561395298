import { Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import GmailAuthComponent from './auth/GmailAuth';

function CollapsibleNavbar(props) {
  // const authUser = useSelector((state) => state.auth.authUser)
  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
 
  return (
    <Navbar collapseOnSelect expand="lg" style={{background: "rgba(255,211,118, 0.9)"}}>
      <Container>
      <NavLink to="/">
        <Navbar.Brand><Image src="logo.svg" alt="Kiddi-Soccer" style={{height: "80px", opacity: 1}}/></Navbar.Brand>
        </NavLink>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto">
            {/* <NavLink className="p-2" to="/activities">Fun Activities</NavLink>
            <NavLink className="p-2" to="/soccer-tips">Soccer Tips</NavLink>
            <NavLink className="p-2" to="/parents-corner">Parent's Corner</NavLink>
            <NavLink className="p-2" to="/contact">Contact Us</NavLink>
             */}
          </Nav>
          <Nav className='p-auto'>
            <GmailAuthComponent />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleNavbar;