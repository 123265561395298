import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authUser: null,
    isAuthenticated: false,
    isAdmin: false
  },
  reducers: {
    signinUser: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.authUser = action.payload;
      state.isAuthenticated = true;
      sessionStorage.setItem("accessToken", action.payload.accessToken);
    },
    logout: (state) => {
      sessionStorage.clear();
      state.authUser = null;
      state.isAuthenticated = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { signinUser, logout } = authSlice.actions

export default authSlice.reducer