// AnimatedTutorials.js
import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';

const AnimatedTutorials = () => {
    return (
        <div className="m-4 p-4 container-background1">
            <Row>
                <Col>
                    <h2 className='header-title'>Animated Tutorials</h2>
                    <p className='subtitle'>Learn basic soccer techniques with these short animated tutorials!</p>

                    {/* Add animated tutorial videos or a video player component here */}

                    <Card>
                        <Card.Body>
                            <Card.Title>Dribbling Tutorial</Card.Title>
                            <Card.Text>Watch and practice dribbling skills with our animated tutorial.</Card.Text>
                            <Button variant="primary">Watch Tutorial</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <h2 className="header-title">Soccer Adventures</h2>
                    <p className='subtitle'>Embark on soccer adventures with these animated character-filled episodes!</p>

                    {/* Add animated adventure videos or a video player component here */}
                    <Card>
                        <Card.Body>
                            <Card.Title>Adventures in Soccerland</Card.Title>
                            <Card.Text>Join our animated characters on exciting soccer adventures!</Card.Text>
                            <Button variant="primary">Watch Adventures</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default AnimatedTutorials;
