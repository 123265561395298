import React from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';

const ParentsCorner = () => {
  return (
    <Container>
      <div className='m-4 p-4 container-background1'>
      <h2 className='header-title'>Parent's Corner</h2>
      <p className='subtitle'>Find valuable information and support for parents in their kids' soccer journey!</p>

        <Row>
            <Col>
      <Card>
        <Card.Body>
          <Card.Title>Safety Tips</Card.Title>
          <Card.Text>
            Ensure a safe soccer experience for your kids with proper safety gear
            and guidelines. Check our resources for more information.
          </Card.Text>
          <Button variant="info">Explore Safety</Button>
        </Card.Body>
      </Card>
      </Col>
      <Col>
      <Card className="">
        <Card.Body>
          <Card.Title>Encouragement and Support</Card.Title>
          <Card.Text>
            Active participation and support from parents make a huge difference!
            Read encouraging messages and success stories from fellow parents.
          </Card.Text>
          <Button variant="info">Read Stories</Button>
        </Card.Body>
      </Card>
      </Col>
      </Row>
      </div>
      {/* Add more content and links as needed */}
    </Container>
  );
};

export default ParentsCorner;
