// RealGameHighlights.js
import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';

const RealGameHighlights = () => {
    return (
        <div className="m-4 p-4 container-background2">
            <Row>
                <Col>
                    <h2 className='header-title'>Real Game Highlights</h2>
                    <p className='subtitle'>Experience the excitement with highlights from real soccer matches!</p>
                    {/* Add real game highlight videos or a video player component here */}
                    <Card>
                        <Card.Body>
                            <Card.Title>Soccer Match Highlights</Card.Title>
                            <Card.Text>Enjoy highlights from a thrilling soccer match with kid-friendly commentary.</Card.Text>
                            <Button variant="primary">Watch Highlights</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <h2 className='header-title'>Incredible Goals</h2>
                    <p className='subtitle'>Witness incredible goals scored by amazing talented players!</p>
                    {/* Add incredible goal videos or a video player component here */}
                    <Card>
                        <Card.Body>
                            <Card.Title>Goal Highlights Compilation</Card.Title>
                            <Card.Text>Watch a compilation of amazing goals scored in various soccer matches.</Card.Text>
                            <Button variant="primary">Watch Goals</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </div>
    );
};

export default RealGameHighlights;
