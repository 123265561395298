// HomePage.js
import React from 'react';
import { Container } from 'react-bootstrap';
import WelcomeBanner from './WelcomeBanner';
import './HomePage.css'; // Import your custom styles

const HomePage = () => {
  return (
    <Container className="m-0">
        <WelcomeBanner />
    </Container>
  );
};

export default HomePage;
